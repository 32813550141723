import React from 'react';
import starts_4 from '../assets/icon/b4.png';

function Loader() {
    return (
        <>
            <div className='Loader-Con'>
                <div className='Loader-el'>
                    <img src={starts_4} className="Loader-Icon" alt="logo" /> 
                    <div className = "Loader-Text">Загрузка<span class="dots"></span></div>
                </div>
            </div>
        </>
    );
}

export default Loader;