import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../assets/css/style_menu.css';

// Импортируем SVG файлы как компоненты
import { ReactComponent as HomeIcon } from '../assets/icon/home.svg';
import { ReactComponent as frendsIcon } from '../assets/icon/frends.svg';
import { ReactComponent as moneyIcon } from '../assets/icon/popol.svg';
import { ReactComponent as vivodIcon } from '../assets/icon/vivod.svg';

function Menu() {
    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const menuElements = [
        { title: "Главная",name: "home", icon: HomeIcon, path: "/" },
        { title: "Друзья",name: "frends", icon: frendsIcon, path: "/friends" },
        { title: "Аренда",name: "topup", icon: moneyIcon, path: "/topup" },
        { title: "Вывод",name: "vivod", icon: vivodIcon, path: "/withdrawal" },
    ];
    useEffect(() => {
        const currentIndex = menuElements.findIndex((element) => element.path === location.pathname);
        if (currentIndex !== -1) {
            setActiveIndex(currentIndex);
        }
    }, [location]);

    const handleItemClick = (index, path) => {
        window.user.card = window.user.card.map(card => {
            if (card.new === 1) {
              card.new = 0; // меняем new на 0
            }
            return card;
          });
          
        setActiveIndex(index);
        navigate(path);
    };

    return (
        <div className="menu-item-con">
        <div className="menu-item">
            {menuElements.map((element, index) => (
                <div
                    key={index}
                    className={`menu-element ${index === activeIndex ? 'active' : ''}`}
                    onClick={() => handleItemClick(index, element.path)}
                >
                    <div className="menu-element-item">
                        <element.icon className={`icon ${element.name}`} style={{ fill: index === activeIndex ? 'var(--active-icon-color)' : 'var(--inactive-icon-color)' }} />
                        <div className="menu-element-item-title" style={{  color: index === activeIndex ? 'var(--active-icon-color)' : 'var(--inactive-icon-color)' }}>{element.title}</div>
                    </div>
                </div>
            ))}
        </div>
        </div>
    );
}

export default Menu;