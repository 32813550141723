import './assets/css/App.css';
import './assets/css/font.css';
import { useState, useEffect } from 'react';
import { TonConnectUIProvider} from '@tonconnect/ui-react';

import {ToastContainer } from 'react-toastify';
import starts from './assets/icon/start.png';
import LazyLoad from 'react-lazy-load';
import starts_1 from './assets/icon/b.png';
import starts_2 from './assets/icon/b2.png';
import starts_3 from './assets/icon/b3.png';
import starts_4 from './assets/icon/b4.png';
import Menu from './components/Menu';
import UserHeader from './components/UserHeader';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Withdrawal from './pages/withdrawal';
import Topup from './pages/topup';
import Friends from './pages/friends';
import Loader from './pages/Loader';
const tg = window.Telegram.WebApp;
function App() {
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [show, setShow] = useState(false);
  const [showS, setShowS] = useState(false);
  const initData = window.Telegram.WebApp.initData ;
  if (!initData) {
    console.error("initData is not set");
  }
  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(initData),
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    };

    fetch('https://api.miningpoolcash.com/auth', requestOptions)
      .then(response => response.json())
      .then(data => {
        localStorage.setItem('token', data.result);
        window.token = localStorage.getItem('token');
        if (!window.token) {
          console.error("Token is not set");
          return;
        }
        if (data.create == true) {
          setStep(1);
        }
        // Set the API endpoint URL
        const apiUrl = 'https://api.miningpoolcash.com/api/users/get';
        
        // Set the request headers
        const headers = {
          'X-Token': `Bearer ${window.token}`,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0'
        };
        
        // Make the GET request to the API endpoint
        fetch(apiUrl, {
          method: 'GET',
          headers: headers,
          cache: 'no-cache'
        })
        .then(response => response.json())
        .then(data => {
          window.user = null;window.user = data.result; setLoading(false); setLoader(false);
        })
        .catch(error => {console.error(error); setLoading(false); setLoader(true);});
      })
      .catch(error => {console.error(error); setLoading(false); setLoader(true);});
    });
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
      setShowS(true);
    }, 350); // задержка в 500мс
  }, [step]); // add step as a dependency
  
  const handleContinueClick = () => {
    setShow(false);
    
    setTimeout(() => {
      setStep(step + 1);
      setShow(true); // restart the animation
    }, 350 + step); // задержка в 500мс
  };
  if (loading) {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <div className='new_user_con'>
              <div className={`new_user_item ${show ? 'show' : ''}`} >
              </div>
            </div>
          </header>
        </div>
      </Router>
    ); // Display loading indicator
  }
  if (step === 1) {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <div className='new_user_con'>
              <div className={`new_user_item ${show ? 'show' : ''}`} >
                <img src={starts_1} style={{ display: 'none' }} alt="logo" />  
                <img src={starts_2} style={{ display: 'none' }} alt="logo" />  
                <img src={starts_3} style={{ display: 'none' }} alt="logo" />  
                <img src={starts_4} style={{ display: 'none' }} alt="logo" />  
                <LazyLoad offset={100} throttle={200}>
                  <img src={starts} className={`new_user_item_img ${showS ? 'show' : ''}`} alt="logo" />  
                </LazyLoad>
                <div class="new_user_item_title">Привет, {window.user.name}!</div>
                <div className="new_user_item_title_con">
                  <div class="new_user_item_message">Мы рады видеть тебя в мире майнинга!</div>
                  <div class="new_user_item_key" onClick={handleContinueClick}>Продолжить</div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </Router>
    );
  } else if (step === 2) {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <div className='new_user_con'>
              <div className={`new_user_item ${show ? 'show' : ''}`}>
                <LazyLoad offset={100} throttle={200}>
                  <img src={starts_1} className={`new_user_item_img ${showS ? 'show' : ''}`} alt="logo" />  
                </LazyLoad>
                <div class="new_user_item_title">Зарабатывай!</div>
                <div className="new_user_item_title_con">
                  <div class="new_user_item_message">Вы фармите в блокчейне и получаете от этого заработок</div>
                  <div class="new_user_item_key" onClick={handleContinueClick}>Продолжить</div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </Router>
    );
  } else if (step === 3) {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <div className='new_user_con'>
              <div className={`new_user_item ${show ? 'show' : ''}`}>
              <LazyLoad offset={100} throttle={200}>
                  <img src={starts_2} className={`new_user_item_img ${showS ? 'show' : ''}`} alt="logo" />  
                </LazyLoad>
                <div class="new_user_item_title">Арендуй видеокарты</div>
                <div className="new_user_item_title_con">
                  <div class="new_user_item_message">Арендуй у нас видеокарты, фарми нашими видеокартами внутри блокчейна!</div>
                  <div class="new_user_item_key" onClick={handleContinueClick}>Продолжить</div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </Router>
    );
  }
  else if (step === 4) {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <div className='new_user_con'>
              <div className={`new_user_item ${show ? 'show' : ''}`}>
              <LazyLoad offset={100} throttle={200}>
                  <img src={starts_3} className={`new_user_item_img ${showS ? 'show' : ''}`} alt="logo" />  
                </LazyLoad> 
                <div class="new_user_item_title">Выводи</div>
                <div className="new_user_item_title_con">
                  <div class="new_user_item_message">Все, что ты нафармил, ты можешь вывести в свой криптокошелек.</div>
                  <div class="new_user_item_key" onClick={handleContinueClick}>Продолжить</div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </Router>
    );
  }
  
  else if (step === 5) {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <div className='new_user_con'>
              <div className={`new_user_item ${show ? 'show' : ''}`}>
                <LazyLoad offset={100} throttle={200}>
                  <img src={starts_4} className={`new_user_item_img ${showS ? 'show' : ''}`} alt="logo" />  
                </LazyLoad>
                <div class="new_user_item_title">Ну что, начнем?</div>
                <div class="new_user_item_key" onClick={handleContinueClick}>Погнали!</div>
              </div>
            </div>
          </header>
        </div>
      </Router>
    );
  }
   else {
    return (
      <TonConnectUIProvider manifestUrl="https://miningpoolcash.com/tonconnect-manifest.json">
        
        <Router>
          
          <div className="App">
            <header className="App-header">
            {loader ? (
              <Loader /> // Показываем Loader, пока loader === true
            ) : (
            <>
              <ToastContainer stacked />
                <UserHeader />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/withdrawal" element={<Withdrawal/>} />
                  <Route path="/topup" element={<Topup />} />
                  <Route path="/friends" element={<Friends />} />
                </Routes>
                <Menu />
              </>
            )}
            </header>
            
          </div>
        </Router>
      </TonConnectUIProvider>
    );
  }
}

export default App;