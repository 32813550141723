import React from 'react';
function LoaderMin() {
    return (
        <>
            <div className='LoaderMin'>
                <div className='Loader-el'>
                    <svg class="spinner" viewBox="0 0 50 50">
                    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                    </svg>
                </div>
            </div>
        </>
    );
}

export default LoaderMin;