import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { TonConnectButton, useTonAddress } from '@tonconnect/ui-react';
import logo from '../avatar.jpg';
import LoaderMin from '../pages/LoaderMin';

function Withdrawal() {
    const [balance, setBalance] = useState(window.user.coin); 
    const [Load, setLoad] = useState(true);
    const [transactions, setTransactions] = useState([]); // Начинаем с пустого массива
    const rawAddress = useTonAddress();
    var st = 0;
    // Функция для обработки нажатия на кнопку "Вывести"
    const handleWithdrawClick = () => {
        if (rawAddress) {
            setLoad(true);
            const apiUrl = 'https://api.miningpoolcash.com/api/users/GiveToken/' + rawAddress;
            const headers = {
                'X-Token': `Bearer ${window.token}`,
                'Content-Type': 'application/json'
            };

            fetch(apiUrl, { method: 'PATCH', headers })
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'success') {
                        window.user.coin = 0;
                        setLoad(false);
                        toast.success("Отлично, мы получили твою заявку и остановили твой майнинг! Нам необходимо пару минут чтобы её обработать.", {
                            autoClose: 9000,
                            theme: "dark",
                            position: "top-center",
                            limit: 3
                        });
                    }
                    else if (data.result === 'error_coin') {
                        toast.error("Минимальная сумма вывода 0.5 RUB", {
                            autoClose: 3000,
                            theme: "dark",
                            position: "top-center",
                            limit: 3
                        });
                    }
                    else {
                        toast.error("Что-то пошло не так", {
                            autoClose: 3000,
                            theme: "dark",
                            position: "top-center",
                            limit: 3
                        });
                    }
                    setLoad(false);
                })
                .catch(error => console.error(error));
        } else {
            toast.error("Пожалуйста, подключите свой кошелек", {
                autoClose: 3000,
                theme: "dark",
                position: "top-center",
            });
        }
    };

    useEffect(() => {
        const fetchTransactions = () => {
            const apiUrl = 'https://api.miningpoolcash.com/api/users/getReceived';
            const headers = {
                'X-Token': `Bearer ${window.token}`,
                'Content-Type': 'application/json'
            };
    
            fetch(apiUrl, { method: 'GET', headers, cache: 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    if (st === 0) {
                        setTransactions(data.result);
                        st = 1;
                    } else {
                        // Проверяем, что data.result является массивом
                        if (Array.isArray(data.result)) {
                            setTransactions(prevTransactions => {
                                const newTransactions = data.result.filter(
                                    newTransaction => !prevTransactions.some(
                                        prevTransaction => prevTransaction.id === newTransaction.id
                                    )
                                );
    
                                // Если новые транзакции есть, добавляем их с классом add_new
                                if (newTransactions.length > 0) {
                                    // Удаляем свойство 'isNew' у всех транзакций
                                    const updatedPrevTransactions = prevTransactions.map(transaction => {
                                        const { isNew, ...rest } = transaction; // Удаляем 'isNew', если есть
                                        return rest;
                                    });
    
                                    // Добавляем новые транзакции с 'isNew'
                                    const newTransactionsWithFlag = newTransactions.map(transaction => ({
                                        ...transaction,
                                        isNew: true // Устанавливаем 'isNew' для новых элементов
                                    }));
    
                                    return [...newTransactionsWithFlag, ...updatedPrevTransactions];
                                }
    
                                return prevTransactions;
                            });

                        } else {
                            console.error('Ожидаем массив с результатами, но получили:', data);
                        }
                    }
    
                    setLoad(false);
                })
                .catch(error => {
                    setLoad(false);
                    console.error(error);
                });
        };
    
        fetchTransactions(); // Запрашиваем данные сразу
        const intervalId = setInterval(fetchTransactions, 5000); // Каждые 5 секунд
    
        return () => clearInterval(intervalId); // Очищаем интервал при размонтировании компонента
    }, []); // Выполняется только один раз при монтировании компонента
    
    useEffect(() => {
        const updateBalance = () => {
            setBalance(window.user.coin);
        };

        updateBalance(); // Update balance initially

        const intervalId = setInterval(updateBalance, 500); // Update balance every 1 second

        return () => {
            clearInterval(intervalId); // Clean up interval on component unmount
        };
    }, []);
    const timeAgo = (unixTimestamp) => {
        const currentTime = Math.floor(Date.now() / 1000); // Текущее время в Unix формате
        const timeDiff = currentTime - unixTimestamp;
    
        if (timeDiff < 60) {
            const seconds = timeDiff;
            if (seconds === 1) return `${seconds} секунда назад`;
            if (seconds >= 2 && seconds <= 4) return `${seconds} секунды назад`;
            return `${seconds} секунд назад`;
        } else if (timeDiff < 3600) {
            const minutes = Math.floor(timeDiff / 60);
            if (minutes === 1) return `${minutes} минута назад`;
            if (minutes >= 2 && minutes <= 4) return `${minutes} минуты назад`;
            return `${minutes} минут назад`;
        } else if (timeDiff < 86400) {
            const hours = Math.floor(timeDiff / 3600);
            if (hours === 1) return `${hours} час назад`;
            if (hours >= 2 && hours <= 4) return `${hours} часа назад`;
            return `${hours} часов назад`;
        } else if (timeDiff < 2592000) { // 30 дней
            const days = Math.floor(timeDiff / 86400);
            if (days === 1) return `${days} день назад`;
            if (days >= 2 && days <= 4) return `${days} дня назад`;
            return `${days} дней назад`;
        } else if (timeDiff < 31536000) { // 1 год
            const months = Math.floor(timeDiff / 2592000);
            if (months === 1) return `${months} месяц назад`;
            if (months >= 2 && months <= 4) return `${months} месяца назад`;
            return `${months} месяцев назад`;
        } else {
            const years = Math.floor(timeDiff / 31536000);
            if (years === 1) return `${years} год назад`;
            if (years >= 2 && years <= 4) return `${years} года назад`;
            return `${years} лет назад`;
        }
    };
    
    return (
        <>
            {Load && <LoaderMin />}
            <div className="item_home_element item_w top">
                <div className="item_withdrawal">
                    <img src={logo} className="item_topup-icon" alt="logo" />
                    <div className='item_friend-icon-text-con'>
                        <div className='item_friend-icon-text'>
                            Как только вы будете готовы, подключите свой кошелек и нажмите на кнопку, вывести.
                        </div>
                    </div>

                    <div className='item_topup-input-con'>
                        <div className='TonConnectButton-V'>
                            <TonConnectButton />
                        </div>

                        <div className="key_vivod-me" onClick={handleWithdrawClick}>
                            Вывести {balance} RUB
                        </div>
                    </div>
                    <div className='item_friend_count'>
                        Минимальная сумма вывода 0.5 рублей. 
                        <br />
                        Денежные средства придут в TON
                    </div>

                </div>
                <div className='title-item-his'>Последние 100 выводов</div>
                {/* Отображаем список транзакций */}
                {transactions.map((transaction, index) => (
                    <div
                        key={transaction.id}
                        className="item_type-m-con"
                        style={transaction.isNew ? { opacity: 0, animation: '0.5s anim-lineUp ease-out forwards' } : {}}
                        onAnimationEnd={() => {
                            if (transaction.isNew) {
                                setTransactions(prev => prev.map(t => t.id === transaction.id ? { ...t, isNew: false } : t));
                            }
                        }}
                    >
                        <div className="item_type-m">
                            <img src={logo} className="item_type-m-icon" alt="logo" />
                            <img src={logo} className="item_type-m-icon item_type-m-icon-b" alt="logo" />
                            <div className="item_type-m-name">{transaction.name}</div>
                            <div className="item_type-m-cash">
                                Вывел: {transaction.coin} RUB
                            </div>
                        </div>
                        <div className="item_type-m-time">
                            {timeAgo(transaction.time)} 
                        </div>
                    </div>
                    
                ))}
            </div>
        </>
    );
}

export default Withdrawal;
