import React, { useState,useEffect } from 'react'; 
import { useNavigate, useLocation } from 'react-router-dom';
import logo_money from '../assets/icon/moneys.png';
import {toast } from 'react-toastify';
import LoaderMin from '../pages/LoaderMin';
import 'react-toastify/dist/ReactToastify.css';
import logo_p from '../assets/icon/zai.gif'
import logo from '../assets/icon/arend.png';
import {TonConnectButton,useTonConnectUI,useIsConnectionRestored,useTonWallet } from '@tonconnect/ui-react';
function Topup() {
    const isConnectionRestored = useIsConnectionRestored();
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();
    const [txInProgress, setTxInProgress] = useState(false);
    const [Load, setLoad] = useState(true);
    const [LoadPay, setLoadPay] = useState(false);
    const navigate = useNavigate();
    const [elements, setElements] = useState([]);
    const tonweb = new window.TonWeb();
    let content;
    switch (true) {
        case !isConnectionRestored:
            content = 'Loading...';
            break;
        case txInProgress:
            content = 'Tx in progress';
            break;
        case !!wallet:
            content = 'Send transaction';
            break;
        default:
        case !wallet:
            content = 'Connect Wallet';
            break;
    }
    useEffect(() => {
        const fetchMarket = () => {
            const apiUrl = 'https://api.miningpoolcash.com/api/market/get';
            const headers = {
                'X-Token': `Bearer ${window.token}`,
                'Content-Type': 'application/json'
            };
    
            fetch(apiUrl, { method: 'GET', headers, cache: 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    setElements(data.result);
                    setLoad(false);
                })
                .catch(error => {
                    setLoad(false);
                    console.error(error);
                });
        };
    
        fetchMarket();
        const intervalId = setInterval(fetchMarket, 5000);
        return () => clearInterval(intervalId);
    }, []); // Выполняется только один раз при монтировании компонента
    const executeRequest = async (hashBase64) => {
        try {
          const apiUrl = `https://api.miningpoolcash.com/api/users/pay/${hashBase64}`;
    
          const headers = {
            'X-Token': `Bearer ${window.token}`,
            'Content-Type': 'application/json',
          };
    
          const response = await fetch(apiUrl, {
            method: 'PATCH',
            headers: headers,
          });
    
          const data = await response.json();
          
    
          // Предположим, что положительный результат будет определяться по наличию какого-то поля в ответе
          // Например, поле "status" должно быть "success". Это условие можно адаптировать под ваш API.
          if (data.status === 'success') {
            setLoadPay(false);
            window.user.card.unshift(data.result);
            navigate('/');
            toast.success("Отлично, мы нашли вашу транзакцию!", {
              autoClose: 3000,
              theme: "dark",
              position: "top-center",
            });
          } else {
            // Если ответ не положительный, повторяем запрос через 1 секунду
            setTimeout(() => executeRequest(hashBase64), 1000);
          }
        } catch (error) {
            setLoadPay(false);
            toast.error("Ох... Что-то пошло не так. Не переживайте, мы уже решаем проблему", {
                autoClose: 3000,
                theme: "dark",
                position: "top-center",
            });
        }
      };
      const onClick = async (pr, t) => {
        
        if (!wallet) {
            tonConnectUI.connectWallet();
        } else {
            setTxInProgress(true);
            let result;
            try {
                setLoadPay(true);
                var time_z = Math.floor(Date.now() / 1000) + 120;
                const apiUrl = 'https://api.miningpoolcash.com/api/users/payCreate/'+t;
                const headers = {
                    'X-Token': `Bearer ${window.token}`,
                    'Content-Type': 'application/json'
                };
    
                // Используем async/await вместо then
                const response = await fetch(apiUrl, { method: 'PATCH', headers, cache: 'no-cache' });
                const data = await response.json();
                if (data.status === 'success') {
                    setLoadPay(false);
                    const text = "HELLO!";
                    const payload = new TextEncoder().encode(text);  // Кодируем строку в байты
                    const paddedPayload = new Uint8Array(payload.length + 10); // Добавляем 10 байтов для "шума"
                    paddedPayload.set(payload);
                    pr = data.result.price * 1e9;
                    let a = new tonweb.boc.Cell();
                    a.bits.writeUint(0, 32);
                    a.bits.writeString(data.result.id);
                    let payloads = tonweb.utils.bytesToBase64(await a.toBoc());
                    result = await tonConnectUI.sendTransaction({
                        validUntil: time_z,
                        messages: [
                            {
                                "address": "UQBDKAiFVI3oSQe-Bxlj_qeHaEskXYatpfEMrKew5A4JdWzF",
                                "payload": payloads,
                                "amount": pr
                            }
                        ]
                    },
                    {
                        notifications: ['before', 'success', 'error'],
                    });
    
                    if (result.error) {
                        setLoadPay(false);
                        toast.error("Ох... Что-то пошло не так", {
                            autoClose: 1500,
                            theme: "dark",
                            position: "top-center"
                        });
                    } else {
                        setLoadPay(false);
                        setLoadPay(true);
                        const bocCellBytes = await tonweb.boc.Cell.oneFromBoc(tonweb.utils.base64ToBytes(result.boc)).hash();
                        const hashBase64 = tonweb.utils.bytesToBase64(bocCellBytes);
                        executeRequest(hashBase64);
                    }
                }
            } catch (error) {
                setLoad(false);
                toast.error("Ох... Что-то пошло не так", {
                    autoClose: 1500,
                    theme: "dark",
                    position: "top-center"
                });
                console.error(error);
            } finally {
                tonConnectUI.setConnectRequestParameters(null);
                tonConnectUI.closeModal();
            }
            setTxInProgress(false);
        }
    };
    
    return (
        <>
                {Load && <LoaderMin />}  {/* Если Load = true, то отображается LoaderMin */}
                {LoadPay && <LoaderMin />}  {/* Если Load = true, то отображается LoaderMin */}
                <div className="item_home_element top">
                <div className='uptime-con'>
                        <div className='uptime-el'>
                            <div className='uptime-title'>Статус доступности:</div>
                            <div className='uptime-status'>99.998%</div>
                        </div>
                        <div class="progress-container top">
                            <style>
                                {`:root {
                                --progress-width: 100%;
                                }`}
                            </style>
                            <progress value="100" max="100"></progress>
                        </div>
                    </div>
                    <div className = "item_topup_pod">
                        <div className='item_topup-icon-text-con'>
                        <img src={logo} className="item_topup-icon" alt="logo" />
                            <div className='item_friend_pod-text'>
                            Видеокарты расположены в дата-центре, мы обеспечиваем максимальную производительность, надежность и доступность к видеокартам
                            </div>
                            <TonConnectButton id = "TonConnectButton" />
                        </div>
                    </div>
                    
                    <div className = "item_topup_con">
                        {elements.map((element) => (
                            <div className='item_topup_element'>
                                <div className='icon-shop'>
                                    <img
                                    src={require(`../assets/icon/${element.photo}`)}
                                    className={`item_up_element-icon ${
                                        Math.max(0, element.endTime - Math.floor(Date.now() / 1000)) > 0 ? 'active-farm-animation' : ''
                                    }`}
                                    alt="logo"
                                    />
                                </div>
                                <div class="item_up_element-name">{element.name}</div>
                                <div className='item_up_element-con-price'>
                                    <div className='item_up_element-con-element'>
                                        <img src={logo_money} className="item_up_element-price-l" alt="logo" />
                                        <div className='item_up_element-price'>
                                        Фарм в час: {element.count} RUB
                                        </div>
                                    </div>
                                </div>
                                <div class="item_up_element-name-arenda-price">Стоимость аренды: {element.price} TON</div>
                                <div className="item_up_element-key-arenda" onClick={() => onClick(element.price,element.ID)}>Взять в аренду</div>
                            </div>
                        ))}
                    </div>
                
                </div>
        </>
    );
}

export default Topup;